/*
 * @Author: 魏鑫
 * @Date: 2019-12-24 11:39:19
 * @LastEditors: 魏鑫
 * @LastEditTime: 2019-12-24 11:39:21
 */
import axios from '@/lib/api.request'
// 续期列表
export const xqList = data => axios.post('hx/abtComHxRiskconMoney/xqList', data)
// 续期保单详情
export const xqShow = params => axios.get('hx/abtComHxRiskconMoney/xqShow', {params})
//续期/续保查询
export const xqListShow = data => axios.post('hx/abtComHxRiskconMoney/xqListShow', data)
//续期/续保详情数据
export const xqDetailShow = data => axios.post('cpk/abtComCpkOrderList/h5orderinfo', data)