<!--
 * @Descripttion:
 * @version:
 * @Author: weixin
 * @Date: 2019-12-25 20:07:18
 * @LastEditors: 张天鹏
 * @LastEditTime: 2020-06-03 15:51:02
 -->

 <template>
  <div class="mainbody">
    <md-tabs>
      <div class="type-in">
        <md-field class="productInfo">
          <div class="productContent contentTitle">{{riskconList.classname }}</div>
          <div class="productContent"><span class="title">保单号</span><span class="content">{{riskconList.policyno}}</span></div>
          <div class="productContent"><span class="title">基本保额</span><span class="content">{{`${mount}`}}万</span></div>
          <div class="productContent"><span class="title">保费</span><span class="content">{{riskconList.tmount}}元</span></div>
          <div class="productContent"><span class="title">保障期限</span><span class="content">{{riskconList.bzyears}}</span></div>
          <div class="productContent"><span class="title">缴费年限</span><span class="content">{{riskconList.yearsnum}}</span></div>
          <div class="productContent"><span class="title">保障时间</span><span class="content">{{riskconList.startbzyears}}至{{riskconList.endbzyears}}</span></div>
        </md-field>
        <md-field class="productInfo" v-for="(item,index) in TB" :key="index + 'tb'">
          <div class="productContent contentTitle">投保人</div>
          <div class="productContent"><span class="title">姓名</span><span class="content">{{item.name}}</span></div>
          <div class="productContent"><span class="title">证件类型</span><span class="content">{{item.cardMap ? item.cardMap.label : ''}}</span></div>
          <div class="productContent"><span class="title">证件号码</span><span class="content">{{item.cardnum}}</span></div>
          <div class="productContent"><span class="title">手机号</span><span class="content">{{item.phone}}</span></div>
          <div class="productContent"><span class="title">邮箱</span><span class="content">{{item.email}}</span></div>
        </md-field>
        <md-field class="productInfo" v-for="(item,index) in BB" :key="index + 'bb'">
          <div class="productContent contentTitle">被保人</div>
          <div class="productContent"><span class="title">与投保人关系</span><span class="content">{{item.shipMap ? item.shipMap.label : ''}}</span></div>
          <div class="productContent"><span class="title">姓名</span><span class="content">{{item.name}}</span></div>
          <div class="productContent"><span class="title">证件类型</span><span class="content">{{item.cardMap ? item.cardMap.label : ''}}</span></div>
          <div class="productContent"><span class="title">证件号码</span><span class="content">{{item.cardnum}}</span></div>
          <div class="productContent"><span class="title">手机号</span><span class="content">{{item.phone}}</span></div>
          <div class="productContent"><span class="title">邮箱</span><span class="content">{{item.email}}</span></div>
        </md-field>
      </div>
    </md-tabs>
  </div>
</template>

<script>
// import { xbShow } from "@/api/hx/newpolicy/index";
import { xqDetailShow } from "@/api/hx/renewal/index";
import { getStorage } from '@/lib/util'
export default {
  data() {
    return {
      // showjb: true,
      // showbb: false,
      // showxz: false,
      // showsyr: false,
      // showtb: false,
      /* 保单详情 */
      policyList: {},
      riskconList: {},
      TB: [],
      BB: [],
      policyno: this.$route.query.policyno,
      appfname: "",
      mount:0,
    };
  },
  created() {
    this.policydetail();
  },
  methods: {
    policydetail() {
      let data = {
        appno: this.policyno,
        comid:getStorage('u_s', {}).comid,
        type:"pc"
      };
      xqDetailShow(data).then(res => {
       this.riskconList = res.data.data;
       this.mount=res.data.data.mount/10000
       this.TB = res.data.data.tbr;
       this.BB = res.data.data.bbr;
       });
    }
  }
};
</script>

 <style lang="stylus" scoped>
 .mainbody {
   background-color: #F7F6FB;
 }

 /deep/ .require {
   .md-field-item-title::after {
     content: '*';
     color: red;
   }
 }

 /deep/ .tjmes {
   .md-field-item-title {
     padding-left: 0;
     font-weight: bold;
   }
 }

 /deep/ .additionalrisks {
   .md-field-item-right {
     img {
       width: 4vw;
       height: 4vw;
     }
   }
 }

 .extrafold {
   display: flex;
   height: 8vh;
   // border-top  10px solid #F8F8FA
   border-bottom: 10px solid #F8F8FA;
   justify-content: space-between;
   align-items: center;

   p {
     font-size: 40px;
     font-family: 'PingFang-SC';
     font-weight: bold;
     color: black;
   }

   img {
     width: 3vw;
     height: 4vw;
   }
 }

 .extrat {
   border-top: 20px solid #F8F8FA;
 }

 .delextra {
   display: flex;
   justify-content: center;
   color: #FE5257;
   cursor: pointer;
 }

 .entry_btn {
   background-color: #228BCF;
   height: 6vh;
   border-radius: 8px;
   color: white;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 2vh;
   margin-bottom: 2vh;
 }
 .productInfo{
    margin: 0.4rem 0.4rem;
    padding: 0.3rem;
    // border-radius: 0.3rem;
 }
 .productContent{
   line-height :0.8rem;
 }
 .productContent>span{
   font-size: 0.4rem;
   font-family: PingFang-SC-Regular;
   font-weight: 400;
   color: #555555;
 }
 .title{
    display: inline-block;
    width: 28%;
 }
 .content{
   display: inline-block;
   width: 72%;
   text-align: right;
 }
 .contentTitle{
   font-size: 0.43rem;
   font-family:PingFang-SC-Bold;
   font-weight: bold;
   color: #373737;
   margin-bottom:0.15rem;
   }
</style>
